import { createContext, useState } from "react";
import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { IntegrationSystem, ProcessingServer, ProcessingTemplate, User } from "./Models";
import Spinner from "../components/Spinner";

export const IsMobile = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return width < 600;
}
export type PiEnvironment = {
  processingServers: ProcessingServer[]
  integrationSystems: IntegrationSystem[]
  monitoringGroups: { Id: string, Title: string }[]
  processingTemplates: ProcessingTemplate[]
}

export const AuthContext = createContext<AuthData>({
  isLoading: false,
  setLoading: (n) => { },
  showMessage: (t, e) => { },
  reload: async () => undefined,
  reloadEnvironment: async () => ({ processingServers: [], integrationSystems: [], monitoringGroups: [], processingTemplates: [] }),
  me: undefined,
  environment: { processingServers: [], integrationSystems: [], monitoringGroups: [], processingTemplates: [] },
});


type SizeVariantsType = ("sm" | "lg" | "xl" | undefined)
export interface AuthData {
  isLoading: boolean
  setLoading: (newState: boolean) => void
  showMessage: (text: string | JSX.Element, isError: boolean, size?: SizeVariantsType) => void
  reload: () => Promise<AuthData | undefined>
  reloadEnvironment: () => Promise<PiEnvironment>
  me: User | undefined
  environment: PiEnvironment,
}

export const AuthContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [me, setMe] = useState<User | undefined>();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [users, setUsers] = useState<User[]>([]);
  const [environment, setEnvironment] = useState<PiEnvironment>({ processingServers: [], integrationSystems: [], monitoringGroups: [], processingTemplates: [] });

  const [messState, setMessState] = useState({
    menuClosed: true,
    message: '' as (string | JSX.Element),
    isMessageErr: false,
    size: undefined as SizeVariantsType
  });
  const showMessage = (text: string | JSX.Element, isError: boolean, size?: SizeVariantsType) => {
    setMessState(s => ({ ...s, message: text, isMessageErr: isError, size: size }));
  }

  const reload = async () => {
    console.log('reload from context')
    setLoading(true);

    try {
      var meReq = await fetch("/Users/Me");
      var { me, message } = await meReq.json() as { me: User, message?: string }
      if (!meReq.ok) throw new Error(message);
      console.log('fetched me', me);
      setMe(me);
      await reloadEnvironment()
      return {
        me
      } as AuthData
    } catch (e) {
      console.log('Ошибка в reload from context', e);
      setMe(undefined);
      return undefined
    } finally {
      setLoading(false);
    }
  }
  const reloadEnvironment = async () => {
    console.log('reloadEnvironment from context')
    setLoading(true);

    try {
      var serversReq = await fetch("/Servers/Servers");
      var newEnvironment = { ...environment }
      newEnvironment.processingServers = await serversReq.json() as ProcessingServer[];
      var systemsReq = await fetch("/Systems/Systems");
      newEnvironment.integrationSystems = await systemsReq.json() as IntegrationSystem[];
      var groupsReq = await fetch("/Monitoring/Groups");
      newEnvironment.monitoringGroups = await groupsReq.json() as { Id: string, Title: string }[];
      var ptReq = await fetch("/ProcessingTemplates/ProcessingTemplates");
      newEnvironment.processingTemplates = await ptReq.json() as ProcessingTemplate[];
      console.log('newEnvironment', newEnvironment);
      setEnvironment(newEnvironment);
      return newEnvironment
    } catch (e) {
      console.log('Ошибка в reloadEnvironment from context', e);
      return environment
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => { reload() }, []);


  return (
    <AuthContext.Provider value={{ isLoading, setLoading, showMessage, reload, reloadEnvironment, me, environment }}>
      <Spinner />
      {children}

      <Modal show={messState.message !== ''} onHide={() => setMessState({ ...messState, message: '' })} centered style={{ zIndex: 11111 }} size={messState.size} id="MessageModal">
        <Modal.Header closeButton>
          <Modal.Title>{messState.isMessageErr ? 'Ошибка' : 'Сообщение'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{messState.message}</p>
        </Modal.Body>
      </Modal>

    </AuthContext.Provider>
  );
};
