import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../models/AuthContextProvider";
import { InputData, InputDataHelper } from "../../models/Models";
import moment from "moment";
import { Button, ButtonGroup, Dropdown, DropdownButton, Pagination } from "react-bootstrap";
import * as Icon from 'react-bootstrap-icons';
import { TypeaheadInputProps } from "react-bootstrap-typeahead/types/types";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';
import { MonitoringFilters } from "./Monitoring";

interface FilterOption {
    filterKey: string;
    label: string;
    value: string;
}

export function Messages(props: {
    f: MonitoringFilters
    setF: (filterParams: MonitoringFilters) => void
    messagesTableHeight: number,
    detailedInputDataId?: string
    setDetailedInputDataId?: (id: string | undefined) => void
}) {
    let context = useContext(AuthContext);
    const [model, setModel] = useState<InputData[]>([]);
    const [totalRows, setTotalRows] = useState(0);
    const [filterOptions, setFilterOptions] = useState<FilterOption[]>([]); // Сохранение вариантов фильтра
    let timeoutId: NodeJS.Timeout;
    var f = props.f;
    var setF = props.setF;
    var messagesTableHeight = props.messagesTableHeight;
    var detailedInputDataId = props.detailedInputDataId;
    const navigate = useNavigate();

    const repeat = async (inputData: InputData) => {
        context.setLoading(true);
        try {
            const response = await fetch(`/Monitoring/Repeat/${inputData.Id}`, { method: 'POST' });
            const { error } = await response.json() as { error?: string };
            if (error) throw error;
            await reload()
        } catch (error) {
            context.showMessage(`Ошибка загрузки ${error ?? ''}`, true);
        } finally {
            context.setLoading(false);
        }
    }

    const reload = async () => {
        try {
            console.log('Загружаем сообщения', f)
            context.setLoading(true);
            var r = await fetch(`/Monitoring/Messages`, { method: 'POST', body: JSON.stringify(f), headers: { 'Content-Type': 'application/json' } });
            var { total, data, filterOptions: newFilterOptions, error } = (await r.json()) as { total?: number, data?: InputData[], filterOptions?: FilterOption[], error?: string };
            context.setLoading(false);
            if (error) throw error
            if (data) setModel(data)
            if (total) {
                setTotalRows(total)
                if (f.Page != 1 && (f.Page - 1) * f.PerPage > total) setF({ ...f, Page: 1 })
            }
            if (newFilterOptions) {
                setFilterOptions(newFilterOptions); // Сохранение вариантов фильтра
            }
        }
        catch (error) {
            context.showMessage(<code>{error as string}</code>, true, "lg")
        }
        finally {
            context.setLoading(false)
        }
    }

    useEffect(() => {
        clearTimeout(timeoutId); // Очищаем предыдущий таймаут
        timeoutId = setTimeout(reload, 1000);
    }, [
        f.FilterSenderIp,
        f.FilterProcessTime,
        f.FilterInputHttpUrl,
        f.FilterTags,
    ]);

    useEffect(() => {
        clearTimeout(timeoutId); // Очищаем предыдущий таймаут
        reload();
    }, [f]);


    const totalPages = Math.ceil(totalRows / f.PerPage);
    const firstRowOffset = (f.Page - 1) * f.PerPage + 1
    const tableHeight = detailedInputDataId ? messagesTableHeight : 'inherit'

    const filterResultOptions = filterOptions.filter(o => o.filterKey == 'result-filter');
    const selectedFilterResultValues = f.FilterResult.split('|')
    const selectedFilterResultOption = selectedFilterResultValues.map(v => filterResultOptions.find(o => o.value.toLowerCase() == v.toLowerCase())).filter(o => o != undefined) as FilterOption[];

    const filterInputIfaceOptions = filterOptions.filter(o => o.filterKey == 'input-iface');
    const selectedFilterInputIfaceValues = (f.FilterInputIface as (string | undefined) ?? '').split('|');
    const selectedFilterInputIfaceOption = selectedFilterInputIfaceValues.map(v => filterInputIfaceOptions.find(o => o.value == v)).filter(o => o != undefined) as FilterOption[];

    const filterOutputIfaceOptions = filterOptions.filter(o => o.filterKey === 'output-iface'); // Опции для фильтра по выходному интерфейсу
    const selectedFilterOutputIfaceValues = (f.FilterOutputIface as string).split('|');
    const selectedFilterOutputIfaceOption = selectedFilterOutputIfaceValues.map(v => filterOutputIfaceOptions.find(o => o.value === v)).filter(o => o !== undefined) as FilterOption[];

    const filterProcessingServerOptions = filterOptions.filter(o => o.filterKey === 'processing-server'); // Опции для фильтра по серверу
    const selectedFilterProcessingServerValues = (f.FilterProcessingServer as string).split('|');
    const selectedFilterProcessingServerOption = selectedFilterProcessingServerValues
        .map(v => filterProcessingServerOptions.find(o => o.value === v))
        .filter(o => o !== undefined) as FilterOption[];

    const filterRequestMethodOptions = filterOptions.filter(o => o.filterKey === 'request-method'); // Опции для фильтра по HTTP-методу
    const selectedFilterRequestMethodValues = (f.FilterRequestMethod as string).split('|');
    const selectedFilterRequestMethodOption = selectedFilterRequestMethodValues
        .map(v => filterRequestMethodOptions.find(o => o.value === v))
        .filter(o => o !== undefined) as FilterOption[];

    const OrderedFieldTitle = (title: string, field: string) => {
        var newDirection = f.OrderField == field ? (f.OrderDirection == 'asc' ? 'desc' : 'asc') : f.OrderDirection;
        return <div onClick={e => setF({ ...f, OrderField: field, OrderDirection: newDirection })}
            className="d-flex flex-row align-items-center gap-2 cursor-pointer">
            <span>{title}</span>
            {f.OrderField == field && f.OrderDirection == 'asc' && <i className="bi bi-caret-down-fill"></i>}
            {f.OrderField == field && f.OrderDirection == 'desc' && <i className="bi bi-caret-up-fill"></i>}
        </div>
    }



    return <div className="rounded-3 overflow-hidden mt-3 bg-white">
        <div style={{ maxHeight: tableHeight, overflowY: 'auto', scrollbarWidth: 'thin' }}>
            <table className="table m-0 table-hover table-sm">
                <thead style={{ insetBlockStart: 0, position: 'sticky' }}>
                    <tr>
                        <th></th>
                        <th>{OrderedFieldTitle('Время получения', 'CreationDate')}</th>
                        <th>Входные системы</th>
                        <th>Входной интерфейс</th>
                        <th>Выходные интерфейсы</th>
                        <th>Выходные системы</th>
                        <th>IP отправителя</th>
                        <th>{OrderedFieldTitle('Тип запроса', 'InputHttpMethod')}</th>
                        <th>Длина запроса</th>
                        <th>Длина ответа</th>
                        <th>{OrderedFieldTitle('Адрес запроса', 'InputHttpUrl')}</th>
                        <th>Серверы</th>
                        <th>{OrderedFieldTitle('Время, ms', 'ProcessingTime')}</th>
                        <th>Тэги</th>
                        <th>{OrderedFieldTitle('Результат', 'ProcessingResult')}</th>
                        <th></th>
                    </tr>
                    <tr>
                        <th className="align-middle text-center px-2">
                            <Icon.Funnel size={15} />
                        </th>
                        <th></th>
                        <th></th>
                        <th>{/* Фильтр по входному интерфейсу */}
                            <Typeahead
                                placeholder="Фильтр..."
                                id="input-iface-filter"
                                labelKey="label"
                                size="sm"
                                multiple
                                options={filterInputIfaceOptions}
                                selected={selectedFilterInputIfaceOption}
                                onChange={(selected) =>
                                    setF({
                                        ...f,
                                        FilterInputIface: selected.map((tt: any) => tt.value ?? tt).join('|')
                                    })
                                }
                            />
                        </th>
                        <th>
                            {/* Фильтр по Выходному интерфейсу */}
                            <Typeahead
                                placeholder="Фильтр..."
                                id="output-iface-filter"
                                size="sm"
                                labelKey="label"
                                multiple
                                options={filterOutputIfaceOptions}
                                selected={selectedFilterOutputIfaceOption}
                                onChange={selected =>
                                    setF({
                                        ...f,
                                        FilterOutputIface: selected.map((tt: any) => tt.value ?? tt).join('|')
                                    })
                                }
                            />
                        </th>
                        <th></th>
                        <th>
                            {/* Фильтр по IP */}
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                placeholder="Фильтр..."
                                value={f.FilterSenderIp} // Используем filterParams.FilterSenderIp
                                onChange={e => setF({ ...f, FilterSenderIp: e.target.value })}
                            />
                        </th>
                        <th>{/* Фильтр по HTTP-методу запроса */}
                            <Typeahead
                                placeholder="Фильтр..."
                                id="request-method-filter"
                                labelKey="label"
                                multiple
                                size="sm"
                                options={filterRequestMethodOptions}
                                selected={selectedFilterRequestMethodOption}
                                onChange={selected =>
                                    setF({
                                        ...f,
                                        FilterRequestMethod: selected.map((tt: any) => tt.value ?? tt).join('|')
                                    })
                                }
                            />
                        </th>
                        <th></th>
                        <th></th>
                        <th>
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                placeholder="Фильтр..."
                                value={f.FilterInputHttpUrl}
                                onChange={(e) => setF({ ...f, FilterInputHttpUrl: e.target.value })}
                            />
                        </th>
                        <th>{/* Фильтр по серверу обработки */}
                            <Typeahead
                                placeholder="Фильтр..."
                                id="processing-server-filter"
                                size="sm"
                                labelKey="label"
                                multiple
                                options={filterProcessingServerOptions}
                                selected={selectedFilterProcessingServerOption}
                                onChange={selected =>
                                    setF({
                                        ...f,
                                        FilterProcessingServer: selected.map((tt: any) => tt.value ?? tt).join('|')
                                    })
                                }
                            />
                        </th>
                        <th>
                            {/* Фильтр по времени обработки */}
                            <input
                                placeholder="Фильтр..."
                                type="text"
                                className="form-control  form-control-sm"
                                value={f.FilterProcessTime}
                                onChange={e => setF({ ...f, FilterProcessTime: e.target.value })}
                            />
                        </th>
                        <th>
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                placeholder="Фильтр..."
                                value={f.FilterTags}
                                onChange={(e) => setF({ ...f, FilterTags: e.target.value })} />
                        </th>
                        <th>
                            <Typeahead
                                placeholder="Фильтр..."
                                id="result-filter"
                                size="sm"
                                labelKey="label"
                                multiple // Разрешить множественный выбор
                                options={filterResultOptions} // Предоставьте варианты результатов
                                selected={selectedFilterResultOption} // Используйте выбранные значения из filterParams
                                onChange={(selected) => setF({ ...f, FilterResult: selected.map((tt: any) => tt.value ?? tt).join('|') })}
                            />
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {model.map(inputData => {
                        //
                        var { finishUnixUTCTS, processTimeMs, isSuccess, textState } = InputDataHelper.GetInto(inputData);
                        return <tr className={detailedInputDataId == inputData.Id ? 'table-warning' : ''} key={inputData.Id}>
                            <td></td>
                            <td>{moment.utc(inputData.CreationDate).local().format('DD.MM.YYYY HH:mm:ss.SSS')}</td>
                            <td>{context.environment.integrationSystems.filter(s => inputData.InputSetup.SystemIds.includes(s.Id)).map(s => s.Title).join(', ')}</td>
                            <td>{inputData.InputSetup.Name}<br /><small>{inputData.InputSetup.Tag}</small></td>
                            <td>{inputData.OutputDatas?.map(od => od.OutputSetup.Name).sort().join(', ')}</td>
                            <td>{context.environment.integrationSystems.filter(s => inputData.OutputDatas?.some(od => od.OutputSetup.SystemIds.includes(s.Id)) == true).map(s => s.Title).join(', ')}</td>
                            <td>{inputData.IpAddr}</td>
                            <td>{inputData.InputHttpMethod}</td>
                            <td>{inputData.ContentLength}</td>
                            <td>{inputData.OutputDatas?.map(od => od.ProcessingContentLength ?? -1).filter(c => c > 0).sort().join(', ')}</td>
                            <td><HumanReadableData InputHttpUrl={inputData.InputHttpUrl} key={inputData.Id} /></td>
                            <td>{Array.from(new Set(inputData.OutputDatas?.map(od => od.OutputSetup.ProcessingServer?.Title).sort())).join(', ')}</td>
                            <td>{processTimeMs > -Infinity ? processTimeMs : '-'}</td>
                            <td>{inputData.Tags?.map(t => <div>{t}</div>)} {inputData.OutputDatas?.map(od => od.Tags?.map(t => <div>{t}</div>)).flat()} </td>
                            <td>{textState}</td>
                            <td>
                                <div className="d-flex justify-content-end align-items-center">

                                    {props.setDetailedInputDataId && <Button variant='white' onClick={() => props.setDetailedInputDataId && props.setDetailedInputDataId(inputData.Id)}>
                                        <Icon.Display height={15} width={15} />
                                    </Button>}

                                    <Button variant="white" onClick={async (e) => {
                                        e.preventDefault();
                                        if (!window.confirm('Отправить повторно?')) return;
                                        await repeat(inputData)
                                    }}>
                                        <Icon.ArrowClockwise height={15} width={15} />
                                    </Button>

                                    <Button variant="white" onClick={() => navigate(`/dev/input/${inputData.InputSetup.Id}`)}>
                                        <Icon.Gear height={20} width={20} />
                                    </Button>

                                </div>
                            </td>
                        </tr>
                    }
                    )}
                </tbody>
            </table>
        </div>

        <div className="w-100 d-flex justify-content-between align-items-center pt-4 px-3">
            <div>Результаты: {firstRowOffset}-{firstRowOffset + model.length - 1} из {totalRows}</div>
            <div className="d-flex gap-3">
                <div>
                    <DropdownButton
                        as={ButtonGroup}
                        variant="light"
                        title={`${f.PerPage} на странице`}
                    >
                        {[5, 10, 50, 100, 500].map(perPage => <Dropdown.Item onClick={() => setF({ ...f, PerPage: perPage })}>{perPage}</Dropdown.Item>)}
                    </DropdownButton>
                </div>

                <Pagination size="sm">
                    <Pagination.First onClick={() => setF({ ...f, Page: 1 })} disabled={f.Page == 1} />
                    <Pagination.Prev onClick={() => setF({ ...f, Page: f.Page - 1 })} disabled={f.Page == 1} />
                    <Pagination.Next onClick={() => setF({ ...f, Page: f.Page + 1 })} disabled={f.Page == totalPages} />
                    <Pagination.Last onClick={() => setF({ ...f, Page: totalPages })} disabled={f.Page == totalPages} />
                </Pagination>
            </div>
        </div>
    </div>;
}


export const HumanReadableData = (props: {
    InputHttpUrl: string | undefined
}) => {
    const [showNotParsed, setShowNotParsed] = useState(false);
    const [parsedData, setParsedData] = useState<{ [key: string]: string }>({});
    const [addr, setAddr] = useState('');

    useEffect(() => {
        if (!props.InputHttpUrl || props.InputHttpUrl == '' || props.InputHttpUrl == '?') {
            setParsedData({});
            return;
        }
        try {
            var urlparts = props.InputHttpUrl.split('?', 2)
            setAddr(urlparts[0])
            if (urlparts.length != 2) {
                return
            }
            var text = urlparts[1]
            const parts = text.split('&');
            var pd: { [key: string]: string } = {};
            parts.forEach(part => {
                const [key, value] = part.split('=');
                const decodedKey = key;
                const decodedValue = decodeURIComponent(value.replace(/\+/g, ' '));
                pd[decodedKey] = decodedValue;
            });
            setParsedData(pd);
        } catch (e) {
            console.error('Ошибка при парсинге URL', props.InputHttpUrl, e);
        }
    }, [props.InputHttpUrl]);

    if (!props.InputHttpUrl) return <></>;
    return <>
        <div className="pb-1">{addr}</div>
        <div className="m-0 ps-0">
            {Object.entries(parsedData).map(([key, value]) => (
                <div key={key} className="pb-1">
                    <strong>{key}:</strong> {value}
                </div>
            ))}
        </div>
        {Object.entries(parsedData).length > 0 && <>
            <span onClick={e => setShowNotParsed(!showNotParsed)} style={{ cursor: 'pointer' }}>
                {!showNotParsed ? <Icon.CaretRightFill /> : <Icon.CaretDownFill />}
                <span className="ms-1" style={{ borderBottom: '1px dashed' }}>как url</span>
            </span>
            {showNotParsed && <code style={{ overflowWrap: "anywhere", color: 'gray' }}>{props.InputHttpUrl}</code>}
        </>}

    </>;
};
