import * as Icon from 'react-bootstrap-icons';
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SearchParamsStateType, useSearchParamsState } from "react-use-search-params-state";
import { AuthContext } from "../../models/AuthContextProvider";
import AdminTemplate from "../AdminTemplate";
import { Message } from "./Message";
import { PeriodSelector } from "./PeriodSelector";
import { GroupsTable } from "./GroupsTable";
import { Messages } from "./Messages";
import moment from "moment";
import { Button } from "react-bootstrap";
import { HeaderPage } from '../HeaderPage';



export interface GroupInfo {
    Id: string,
    Key: any,
    MaxCreationDate: string | undefined
    Count: number
    FailureCount: number
    SuccessCount: number
    PlanedCount: number
}

/*
export const filtersDefaults: SearchParamsStateType = {
    Page: { type: 'number', default: 1 },
    PerPage: { type: 'number', default: 30 },

    FilterTags: { type: 'string', default: '' }, // Добавлен фильтр по FilterTags
    FilterInputHttpUrl: { type: 'string', default: '' }, // Добавлен фильтр по адресу запроса
    FilterResult: { type: 'string', default: '' }, // Добавлен фильтр по результату
    FilterInputIface: { type: 'string', default: '' },
    FilterOutputIface: { type: 'string', default: '' },
    FilterProcessingServer: { type: 'string', default: '' },
    FilterRequestMethod: { type: 'string', default: '' },
    FilterSenderIp: { type: 'string', default: '' },
    FilterProcessTime: { type: 'string', default: '' },
    FilterPeriodStart: { type: 'number', default: -1 },
    FilterPeriodEnd: { type: 'number', default: -1 },

    OrderField: { type: 'string', default: 'CreationDate' },
    OrderDirection: { type: 'string', default: 'desc' },
    GroupBy: { type: 'string', default: 'None' },
    GroupsPeriodStart: { type: 'number', default: moment().utc().unix() - 3600 * 24 },
    GroupsPeriodEnd: { type: 'number', default: moment().utc().unix() },
    SelectedGroupId: { type: 'string', default: '' }
};*/

export type MonitoringFilters = {
    Page: number
    PerPage: number

    FilterTags: string
    FilterInputHttpUrl: string
    FilterResult: string
    FilterInputIface: string
    FilterOutputIface: string
    FilterProcessingServer: string
    FilterRequestMethod: string
    FilterSenderIp: string
    FilterProcessTime: string

    FilterPeriodStart: number
    FilterPeriodEnd: number

    OrderField: string
    OrderDirection: string
    GroupBy: string
    GroupsPeriodStart: number
    GroupsPeriodEnd: number
    SelectedGroupId: string
}

export function Monitoring() {
    let context = useContext(AuthContext);
    var navigate = useNavigate();
    const [f, setF] = useState<MonitoringFilters>(() => {
        var defaults = { 
            Page: 1,
            PerPage: 30,
            OrderField: 'CreationDate',
            OrderDirection: 'desc',
            GroupBy: 'None',
            FilterPeriodStart: -1,
            FilterPeriodEnd: -1,
            FilterInputIface: '',
            FilterOutputIface: '',
            FilterProcessingServer: '',
            FilterRequestMethod: '',
            FilterSenderIp: '',
            FilterProcessTime: '',
            FilterTags: '',
            FilterInputHttpUrl: '',
            FilterResult: '',
            SelectedGroupId: ''
         } as MonitoringFilters
        try {
            defaults = JSON.parse(localStorage['monitoringFilters']) as MonitoringFilters
        } catch (error) {
            console.error('Ошибка загрузки фильтров из localStorage:', error);
        } finally {
            defaults.GroupsPeriodStart = moment().unix() - 3600 * 24;
            defaults.GroupsPeriodEnd = moment().unix();
        }
        return defaults;
    })
    useEffect(() => {
        // Сохранение фильтров в localStorage при изменении
        console.log('Сохранение фильтров в localStorage', f)
        localStorage.setItem('monitoringFilters', JSON.stringify(f));
    }, [f]);

    const [detailedInputDataId, setDetailedInputDataId] = useState<string | undefined>(undefined);
    const [groupsTableHeight, setGroupsTableHeight] = useState<number>(JSON.parse(window.localStorage['groupsTableHeight'] || '100') || 100);
    const [messagesTableHeight, setMessagesTableHeight] = useState<number>(JSON.parse(window.localStorage['messagesTableHeight'] || '100') || 100);

    useEffect(() => { window.localStorage['groupsTableHeight'] = groupsTableHeight; }, [groupsTableHeight]);
    useEffect(() => { window.localStorage['messagesTableHeight'] = messagesTableHeight; }, [messagesTableHeight]);


    return <AdminTemplate>
        <div className="p-3">
            <HeaderPage title="Мониторинг" />
            <div className="d-flex p-2 w-100 justify-content-end align-items-center">
                <div className="d-flex flex-row align-items-center gap-2">
                    Период:
                    <PeriodSelector
                        periodStart={f.GroupsPeriodStart}
                        periodEnd={f.GroupsPeriodEnd}
                        setPeriodStart={(unix) => setF({ ...f, GroupsPeriodStart: unix })}
                        setPeriodEnd={(unix) => setF({ ...f, GroupsPeriodEnd: unix })} />
                </div>
            </div>

            <GroupsTable
                groupsTableHeight={groupsTableHeight}
                f={f}
                setF={setF} />

            {f.SelectedGroupId && f.SelectedGroupId != '' && <>
                <hr className="py-1 border-0" style={{ cursor: 'row-resize', backgroundColor: '#DEE1FF', opacity: 1 }} onMouseDown={(mouseDownEvent) => {
                    const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY };
                    var onMouseMove = (mouseMoveEvent: MouseEvent) => setGroupsTableHeight(() => (groupsTableHeight - startPosition.y + mouseMoveEvent.pageY));
                    var onMouseUp = () => document.body.removeEventListener("mousemove", onMouseMove);
                    document.body.addEventListener("mousemove", onMouseMove);
                    document.body.addEventListener("mouseup", onMouseUp, { once: true });
                }} />

                <Messages
                    f={f}
                    setF={setF}
                    detailedInputDataId={detailedInputDataId}
                    setDetailedInputDataId={setDetailedInputDataId}
                    messagesTableHeight={messagesTableHeight} />



                {detailedInputDataId && <><hr className="py-1 border-0" style={{ cursor: 'row-resize', backgroundColor: '#DEE1FF', opacity: 1 }} onMouseDown={(mouseDownEvent) => {
                    const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY };
                    var onMouseMove = (mouseMoveEvent: MouseEvent) => setMessagesTableHeight(() => (messagesTableHeight - startPosition.y + mouseMoveEvent.pageY));
                    var onMouseUp = () => document.body.removeEventListener("mousemove", onMouseMove);
                    document.body.addEventListener("mousemove", onMouseMove);
                    document.body.addEventListener("mouseup", onMouseUp, { once: true });
                }} />
                    <Message inputDataId={detailedInputDataId} setInputDataId={setDetailedInputDataId}></Message></>}

            </>}
        </div>
    </AdminTemplate>;
}

