import { FormEvent, FormEventHandler, useCallback, useContext, useEffect, useState } from "react";
import { InputSetup, InputTypes, IntegrationSystem, OutputSetup, OutputTypes, ProcessingServer, ProcessingTypes } from "../../models/Models";
import AdminTemplate from "../AdminTemplate";
import { AuthContext, AuthData } from "../../models/AuthContextProvider";
import { Button, Container, Form, Modal } from "react-bootstrap";
import * as Icon from 'react-bootstrap-icons';
import { useNavigate } from "react-router-dom";
import { Server } from "http";
import { InputInterfaceEditor } from "../dev/InputInterfaceEditor";
import moment from "moment";
import { HeaderPage } from "../HeaderPage";

async function Remove(context: AuthData, inputId: string, ok: () => Promise<any>) {
    console.log('Удалить интеграционную систему');
    context.setLoading(true);
    try {
        const response = await fetch(`/Systems/Delete/${inputId}`, { method: 'DELETE' });
        const { message, error } = await response.json() as { message?: string, error?: string };
        if (message) {
            await ok();
            if (message != 'OK') context.showMessage(message, false);
            return true
        }
        if (error) {
            context.showMessage(error, true);
        }
    } catch (error) {
        context.showMessage(`Ошибка загрузки ${error ?? ''}`, true);
    } finally {
        context.setLoading(false);
    }
    return false
};

export function SettingSystems() {
    const navigate = useNavigate();
    const [editor, setEditor] = useState<IntegrationSystem | undefined>(undefined);
    let context = useContext(AuthContext);
    var model = context.environment.integrationSystems;

    return (<AdminTemplate>
        <div className="p-3">
            <HeaderPage title="Управление интеграционными системами" />
            <div>
                <Button onClick={() => setEditor({
                    Id: crypto.randomUUID(),
                    Title: 'Новая система'
                } as IntegrationSystem)}>Добавить интеграционную систему</Button>
            </div>
            {model.length == 0 && <>Пока пусто</>}
            {model.length > 0 && <div className="rounded-3 overflow-hidden mt-3">
                <table className="table m-0 align-middle table-hover">
                    <thead>
                        <tr>
                            <th>GUID интеграционной системы</th>
                            <th>Название</th>
                            <th>Дата добавления</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {model.map(intsys => <tr>
                            <td>{intsys.Id}</td>
                            <td>{intsys.Title}</td>
                            <td>{moment.tz(intsys.CreationDate, 'UTC').local().format('DD.MM.YYYY HH:mm')}</td>
                            <td>
                                <div className="d-flex justify-content-end align-items-center">
                                    <Button variant='white' onClick={() => setEditor({ ...intsys })}>
                                        <Icon.Pencil height={15} width={15} />
                                    </Button>

                                    <Button variant="white" onClick={() => {
                                        if (!window.confirm('Точно удалить?')) return;
                                        Remove(context, intsys.Id, context.reloadEnvironment)
                                    }}>
                                        <Icon.Trash3 height={15} width={15} />
                                    </Button>
                                </div>
                            </td>
                        </tr>)}
                    </tbody>
                </table>
            </div>}
        </div>
        {editor && <Editor editor={editor} setEditor={setEditor} reload={context.reloadEnvironment}></Editor>}
    </AdminTemplate>
    );
}


function Editor(props: {
    editor: IntegrationSystem
    setEditor: React.Dispatch<React.SetStateAction<IntegrationSystem | undefined>>
    reload: () => void
}) {
    let context = useContext(AuthContext);

    const update = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        context.setLoading(true);
        try {
            const response = await fetch(`/Systems/Save`, { method: 'POST', body: JSON.stringify(props.editor), headers: { 'Content-Type': 'application/json' } });
            const { message, error } = await response.json() as { message?: string, error?: string };
            if (message) {
                await props.reload()
                if (message != 'OK') context.showMessage(message, false);
                props.setEditor(undefined)
            }
            if (error) throw error
        } catch (error) {
            context.showMessage(<code>{`Ошибка загрузки ${error ?? ''}`}</code>, true, 'lg');
        } finally {
            context.setLoading(false);
        }
    }


    return (
        <Modal show={props.editor != undefined} onHide={() => props.setEditor(undefined)} centered size="xl">
            <Modal.Header closeButton className="mt-2">
                <div>
                    <h1 className="mt-3">{props.editor.Title}</h1>
                    <h2 className="mb-4">Интеграционная система</h2>
                </div>
            </Modal.Header>
            <Modal.Body className="overflow-y-auto">
                <Container>
                    <Form onSubmit={update}>
                        <Form.Group className="has-validation w-100">
                            <Form.Label>GUID</Form.Label>
                            <Form.Control value={props.editor.Id} readOnly disabled />
                        </Form.Group>

                        <Form.Group className="has-validation w-100 mt-4">
                            <Form.Label>Название</Form.Label>
                            <Form.Control placeholder="Название" value={props.editor.Title} required onChange={(e) => props.setEditor(({ ...props.editor, Title: e.target.value }))} />
                        </Form.Group>

                        <div className="d-flex mt-3" style={{ gap: '1rem' }}>
                            <Button variant="primary" type="submit">Сохранить</Button>
                            <Button variant="secondary-outline" onClick={() => props.setEditor(undefined)}>Отмена</Button>
                        </div>
                    </Form>
                </Container>
            </Modal.Body>
        </Modal>
    )
}