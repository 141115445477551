import * as Icon from 'react-bootstrap-icons';
import { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import moment, { duration } from 'moment';
import { AuthContext } from '../../models/AuthContextProvider';
import { GroupInfo, MonitoringFilters } from './Monitoring';
import { InputSetup, OutputSetup, ProcessingServer, clone } from '../../models/Models';

export function GroupKey(props: {
    group: any,
    val: any
}) {
    if (props.group == 'ProcessTimeMs')
        return <>{props.val - 5} - {props.val + 5} ms</>

    if (props.group == 'InIf') {
        var inputSetup = props.val as InputSetup
        return <>{inputSetup.Name}<br /><small>{inputSetup.Tag}</small></>
    }
    if (props.group == 'OutIf') {
        var outputSetup = props.val as OutputSetup
        return <>{outputSetup.Name}<br />{outputSetup.Tag}</>
    }
    if (props.group == 'Result') {
        var isFailure = props.val as boolean
        return (isFailure ? <span className="text-danger">Сбой</span> : <span className="text-success">Успешно</span>);
    }
    if (props.group == 'ProcServer') {
        var server = props.val as ProcessingServer
        return <>{server.Title}<br />{server.Host}</>
    }
    if (props.group == 'ReceivedTimeDay') {
        var start = moment.utc(props.val).startOf('day')
        var end = moment.utc(props.val).endOf('day')
        return <>{start.local().format('DD.MM.YYYY')} - {end.local().format('DD.MM.YYYY')}</>
    }
    if (props.group == 'ReceivedTimeHour') {
        var start = moment.utc(props.val.Date)
        start.set('hour', props.val.Hour)
        var end = start.clone().endOf('hour')
        return <>{start.local().format('DD.MM.YYYY HH:mm')} - {end.local().format('DD.MM.YYYY HH:mm')}</>
    }
    return <>-</>
}

export function GroupsTable(props: {
    groupsTableHeight: number
    f: MonitoringFilters
    setF: (filterParams: MonitoringFilters) => void
}) {
    let context = useContext(AuthContext);
    const [groups, setGroups] = useState<GroupInfo[]>([]);
    const [selectedGroup, setSelectedGroup] = useState<GroupInfo | undefined>(undefined);
    const tableHeight = selectedGroup ? props.groupsTableHeight : 'inherit'
    const groupVariants = context.environment.monitoringGroups;

    useEffect(() => {
        setSelectedGroup(undefined);
        setGroups([]);
        (async () => {
            try {
                context.setLoading(true);
                var r = await fetch(`/Monitoring/Groups`, { method: 'POST', body: JSON.stringify(props.f), headers: { 'Content-Type': 'application/json' } });
                var { Groups, error, GroupId } =  await r.json() as { GroupId?: number, KeyType?: string, Groups?: GroupInfo[], error?: string };
                if (error) throw error
                if (Groups) {
                    setGroups(Groups);
                    var oldSelection = Groups.find(g => g.Id == props.f.SelectedGroupId)
                    if (!oldSelection && Groups.length == 1) oldSelection = Groups[0];
                    setSelectedGroup(oldSelection);
                }
                console.log(`Загружены группы GroupId=${GroupId}`, Groups)
            }
            catch (error) {
                context.showMessage(error as string, true)
            }
            finally {
                context.setLoading(false)
            }
        })()
    }, [props.f.GroupBy,
    props.f.GroupsPeriodStart,
    props.f.GroupsPeriodEnd]);


    /*var getValues = function () {
        var values = {}
        for (var _i = 0, _a = Object.entries(MonitoringFilters); _i < _a.length; _i++) {
            var _b = _a[_i], paramName = _b[0], paramDefinition = _b[1];
            if (paramName.startsWith('Filter')) values = { ...values, [paramName]: paramDefinition.default };
        }
        return values;
    };*/
    // useEffect(()=>{
    //     // при смене группировки очищаем фильтры
    //     props.setFilterParams(getValues());        
    // }, [props.filterParams.GroupBy])

    useEffect(() => {
        console.log('useEffect props.filterParams.SelectedGroupId')
        console.log('GroupBy', props.f.GroupBy)
        console.log('selectedGroup', selectedGroup);
        if (!selectedGroup) return

        var newFilter = { ...props.f,
             SelectedGroupId: selectedGroup?.Id,
             FilterProcessTime: '',
             FilterInputIface: '',
             FilterOutputIface: '',
             FilterResult: '',
             FilterProcessingServer: '',
             FilterPeriodStart: 0,
             FilterPeriodEnd: 0,
             Page: 1 } as MonitoringFilters
        if (props.f.GroupBy == 'ProcessTimeMs') {
            props.setF({ ...newFilter, FilterProcessTime: `>${selectedGroup?.Key - 5}|<${selectedGroup?.Key + 5}` })
        }
        if (props.f.GroupBy == 'InIf') {
            props.setF({ ...newFilter, FilterInputIface: selectedGroup?.Key.Id ?? '' })
        }
        if (props.f.GroupBy == 'OutIf') {
            props.setF({ ...newFilter, FilterOutputIface: selectedGroup?.Key.Id ?? '' })
        }
        if (props.f.GroupBy == 'Result') {
            console.log(`Фильтр по ${props.f.GroupBy} значение`, selectedGroup?.Key)
            props.setF({ ...newFilter, FilterResult: (selectedGroup?.Key as boolean | undefined)?.toString() ?? '' })
        }
        if (props.f.GroupBy == 'ProcServer') {
            props.setF({ ...newFilter, FilterProcessingServer: selectedGroup?.Key.Id ?? '' })
        }
        if (props.f.GroupBy == 'ReceivedTimeDay') {
            var start = moment.utc(selectedGroup?.Key).startOf('day')
            var end = moment.utc(selectedGroup?.Key).endOf('day')
            props.setF({ ...newFilter, FilterPeriodStart: start.unix(), FilterPeriodEnd: end.unix() })
        }
        if (props.f.GroupBy == 'ReceivedTimeHour') {
            console.log('Фильтр по ReceivedTimeHour', selectedGroup?.Key)
            var start = moment.utc(selectedGroup?.Key.Date)
            start.set('hour', selectedGroup?.Key.Hour)
            var end = start.clone().endOf('hour')
            console.log(`ReceivedTimeHour start ${start} UTC (${start.unix()}), ReceivedTimeHour end ${end.toString()} UTC (${end.unix()})`)
            props.setF({ ...newFilter, FilterPeriodStart: start.unix(), FilterPeriodEnd: end.unix() })
        }
        if (props.f.GroupBy == 'None') {
            props.setF({ ...newFilter })
        }
    }, [selectedGroup]);


    return <>

        {groups.length == 0 && <>
            <div className='text-center'>Нет данных за указанный период</div>
        </>}

        {groups.length > 0 && <div className="rounded-3 overflow-hidden mt-3 bg-white">

            <div className="d-flex flex-row align-items-center gap-2 p-3">
                <Button variant="link" onClick={e => props.setF({ ...props.f, GroupBy: 'None' })}>Все сообщения</Button>
                <div>|</div>
                <div className="d-flex flex-row align-items-center gap-2">
                    <span>Группы:</span>
                    {groupVariants.map(group => <>{group.Id != 'None' && <Button
                        variant={`${group.Id == props.f.GroupBy ? 'primary' : 'outline-primary'}`}
                        className="rounded-pill"
                        onClick={e => {
                            props.setF({ ...props.f, GroupBy: group.Id, SelectedGroupId: '' })
                        }
                        }>
                        {group.Title}
                    </Button>}</>)}
                </div>
            </div>

            <div style={{ maxHeight: tableHeight, overflowY: 'auto', scrollbarWidth: 'thin' }}>
                <table className="table m-0 align-middle table-hover">
                    <thead style={{ insetBlockStart: 0, position: 'sticky' }}>
                        <tr>
                            <th></th>
                            {props.f.GroupBy != 'None' && <th>Группа</th>}
                            <th>Последнее сообщение	</th>
                            <th>Количество сообщений</th>
                            <th>Ошибки обработки</th>
                            <th>Успешно обработанных</th>
                            <th>Запланировано</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {groups.map(g => {
                            //
                            return <tr className={selectedGroup?.Id == g.Id ? 'table-warning' : ''}>
                                <td className='text-center align-middle'>
                                    <Icon.CircleFill className={g.FailureCount > 0 ? 'text-danger' : 'text-success'} />
                                </td>
                                {props.f.GroupBy != 'None' && <td><GroupKey val={g.Key} group={props.f.GroupBy} /></td>}
                                <td>{moment.utc(g.MaxCreationDate).local().format('DD.MM.YYYY HH:mm:ss')}</td>
                                <td>{g.Count}</td>
                                <td>{g.FailureCount}</td>
                                <td>{g.SuccessCount}</td>
                                <td>{g.PlanedCount}</td>

                                <td>
                                    <div className="d-flex justify-content-end align-items-center">
                                        <Button variant='white' onClick={() => setSelectedGroup(g)}>
                                            <Icon.Display height={15} width={15} />
                                        </Button>
                                    </div>
                                </td>
                            </tr>
                        }
                        )}
                    </tbody>
                </table>
            </div>
        </div>}

    </>

}