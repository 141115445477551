import { useCallback, useContext, useEffect, useState } from "react";
import { InputSetup, InputTypes, OutputSetup, OutputTypes, ProcessingTypes } from "../../models/Models";
import AdminTemplate from "../AdminTemplate";
import { AuthContext, AuthData } from "../../models/AuthContextProvider";
import { Button } from "react-bootstrap";
import { InputInterfaceEditor } from "./InputInterfaceEditor";
import * as Icon from 'react-bootstrap-icons';
import { OutputInterfaceEditor } from "./OutputInterfaceEditor";
import { useNavigate, useParams } from "react-router-dom";
import { HeaderPage } from "../HeaderPage";
import { useSearchParamsState } from "react-use-search-params-state";
import { Messages } from "../monitoring/Messages";
import { PeriodSelector } from "../monitoring/PeriodSelector";
import { Message } from "../monitoring/Message";
import { OutputAiChat } from "./OutputAiChat";
import { MonitoringFilters } from "../monitoring/Monitoring";
import moment from "moment";

export async function RemoveInput(context: AuthData, inputId: string, ok: () => Promise<void>) {
    console.log('Удалить входной интерфейс');
    context.setLoading(true);
    try {
        const response = await fetch(`/Integrations/DeleteInputInterface/` + inputId, { method: 'DELETE' });
        const { message, error } = await response.json() as { message?: string, error?: string };
        if (message) {
            await ok();
            if (message != 'OK') context.showMessage(message, false);
            return true
        }
        if (error) {
            context.showMessage(error, true);
        }
    } catch (error) {
        context.showMessage(`Ошибка загрузки ${error ?? ''}`, true);
    } finally {
        context.setLoading(false);
    }
    return false
};

export async function RemoveOutput(context: AuthData, outputId: string, ok: () => Promise<void>) {
    console.log('Удалить выходной интерфейс');
    context.setLoading(true);
    try {
        const response = await fetch(`/Integrations/DeleteOutputInterface/` + outputId, { method: 'DELETE' });
        const { message, error } = await response.json() as { message?: string, error?: string };
        if (message) {
            await ok();
            if (message != 'OK') context.showMessage(message, false);
            return true
        }
        if (error) {
            context.showMessage(error, true);
        }
    } catch (error) {
        context.showMessage(`Ошибка загрузки ${error ?? ''}`, true);
    } finally {
        context.setLoading(false);
    }
    return false
};


export function CreateOutput(inputSetup: InputSetup, context: AuthData) {
    console.log('Создать выходной интерфейс');
    if (context.environment.processingServers.length == 0) {
        context.showMessage('Для создания обработчика необходим хотя бы один сервер обработки', true);
        return undefined
    }
    if (context.environment.processingTemplates.length == 0) {
        context.showMessage('Для создания обработчика необходим хотя бы один шаблон обработки', true);
        return undefined
    }
    return {
        Id: crypto.randomUUID(), InputSetupId: inputSetup.Id, Name: '', Tag: '',
        AddedDate: moment().utc().toISOString(),
        EditorPort: 0,
        ParallelTasks: 1,
        ProcessingTries: 3,
        OutputType: OutputTypes.None,
        ProcessingType: ProcessingTypes.Python, 
        ProcessingTriesPauseSeconds: 5, 
        InputSetup: inputSetup, 
        SystemIds: [],

        ProcessingTemplateId: context.environment.processingTemplates[0].Id,
        ProcessingTemplate: context.environment.processingTemplates[0],
        ProcessingServer: context.environment.processingServers[0],
        ProcessingServerId: context.environment.processingServers[0].Id
    } as OutputSetup
}

export function CreateInput() {
    console.log('Создать входной интерфейс');
    return { Id: crypto.randomUUID(), InputType: InputTypes.Request, Name: '', Tag: '', ResponseTimeoutSeconds: 60, AddedDate: moment.utc().toISOString(), SystemIds: [] } as InputSetup
};
export async function OpenIDE(context: AuthData, outputSetupId: string, outputDataId?: string | undefined) {
    context.setLoading(true);
    try {
        const response = await fetch(`/Integrations/GetIdeLink/${outputSetupId}?outputDataId=${outputDataId}`, { method: 'POST' });
        const { error, url } = await response.json() as { error?: string, url: string };
        if (error) throw error;
        console.log('IDE link: ', url);
        window.open(url)
    } catch (error) {
        context.showMessage(`Ошибка загрузки ${error ?? ''}`, true);
    } finally {
        context.setLoading(false);
    }
}

type IsNeedDeploy = {
    OutputSetupId: string
    IsNeedDeploy: boolean
    IsDeployingNow: boolean
}

export function DevsIntegration() {
    const navigate = useNavigate();
    let { inputSetupId } = useParams();
    const [model, setModel] = useState<InputSetup | undefined>(undefined)
    const [deployViewModel, setDeployViewModel] = useState<IsNeedDeploy[] | undefined>(undefined)
    const [inputEditor, setInputEditor] = useState<InputSetup | undefined>(undefined);
    const [outputEditor, setOutputEditor] = useState<OutputSetup | undefined>(undefined);
    const [outputAiChat, setOutputAiChat] = useState<OutputSetup | undefined>(undefined);
    let context = useContext(AuthContext);
    const [f, setF] = useState<MonitoringFilters>(() => {
        var defaults = {
            PerPage: 30,
            OrderField: 'CreationDate',
            OrderDirection: 'desc',
            FilterInputIface: '',
            FilterOutputIface: '',
            FilterProcessingServer: '',
            FilterRequestMethod: '',
            FilterSenderIp: '',
            FilterProcessTime: '',
            FilterTags: '',
            FilterInputHttpUrl: '',
            FilterResult: '',
        } as MonitoringFilters
        try {
            defaults = JSON.parse(localStorage[`integrationFilters_${inputSetupId}`]) as MonitoringFilters
        } catch (error) {
            console.error('Ошибка загрузки фильтров из localStorage:', error);
        } finally {
            defaults.GroupsPeriodStart = -1;
            defaults.GroupsPeriodEnd = -1;
            defaults.FilterPeriodStart = moment().unix() - 3600 * 24;;
            defaults.FilterPeriodEnd = moment().unix();
            defaults.GroupBy = 'None';
            defaults.SelectedGroupId = '';
            defaults.Page = 1;
            if (inputSetupId) defaults.FilterInputIface = inputSetupId;
        }
        return defaults;
    })
    const [detailedInputDataId, setDetailedInputDataId] = useState<string | undefined>(undefined);

    // Сохранение фильтров в localStorage при изменении
    useEffect(() => {
        // Сохранение фильтров в localStorage при изменении
        console.log('Сохранение фильтров в localStorage', f)
        localStorage.setItem(`integrationFilters_${inputSetupId}`, JSON.stringify(f));
    }, [f]);

    useEffect(() => { reload() }, []);

    const reload = async function () {
        context.setLoading(true);
        console.log('Загрузка входного интерфейса', inputSetupId)
        if (inputSetupId) setF({ ...f, FilterInputIface: inputSetupId })
        try {
            const response = await fetch(`/Integrations/InputSetup/${inputSetupId}`);
            const { inputSetup, error, needDeploy } = await response.json() as { inputSetup: InputSetup, error?: string, needDeploy: IsNeedDeploy[] };
            if (error) throw error;
            setModel(inputSetup)
            setDeployViewModel(needDeploy)
        } catch (error) {
            context.showMessage(`Ошибка загрузки ${error ?? ''}`, true);
        } finally {
            context.setLoading(false);
        }
    };

    const deploy = async (outputSetup: OutputSetup) => {
        context.setLoading(true);
        try {
            const response = await fetch(`/Integrations/DeployOutputInterface/${outputSetup.Id}`, { method: 'POST' });
            const { error } = await response.json() as { error?: string };
            if (error) throw error;
            await reload()
        } catch (error) {
            context.showMessage(`Ошибка загрузки ${error ?? ''}`, true);
        } finally {
            context.setLoading(false);
        }
    }



    if (!model) return <AdminTemplate>
        <h2 className='text-center mt-5'>Загрузка...</h2>
    </AdminTemplate>
    return (<AdminTemplate>
        <div className="p-3">
            <HeaderPage title={model.Name.length > 0 ? model.Name : 'Интеграция'} middle1='Интеграции' middle1link='/dev/integrations' />


            <h2 className="mt-4">Входной интерфейс</h2>
            <div className="rounded-3 overflow-hidden mt-3">
                <table className="table m-0 align-middle table-hover">
                    <thead>
                        <tr>
                            <th>Название</th>
                            <th>Тип</th>
                            <th>Сколько ждать ответ, сек</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="table-editor">
                                {model.Name}<br />
                                <small>{model.Tag}</small>
                            </td>
                            <td>
                                {model.InputType}
                            </td>
                            <td>
                                {model.ResponseTimeoutSeconds}
                            </td>
                            <td>
                                <div className="d-flex justify-content-end">
                                    <Button variant="white" onClick={() => navigate(`/monitor/messages?GroupBy=None&FilterInputIface=${model.Id}`)}>
                                        <Icon.Display height={20} width={20} />
                                    </Button>
                                    <Button variant="white" onClick={() => setInputEditor({ ...model })}>
                                        <Icon.Pencil height={20} width={20} />
                                    </Button>
                                    <Button variant="white" onClick={() => {
                                        if (!window.confirm('Точно удалить?')) return;
                                        RemoveInput(context, model.Id, async () => navigate('/dev/integrations'))
                                    }}>
                                        <Icon.Trash3 height={20} width={20} />
                                    </Button>
                                </div>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>


            <h2 className="mt-4 mb-3">Выходные интерфейсы <small>Всего: {model.OutputSetups?.length}</small></h2>
            <div>
                <Button onClick={() => setOutputEditor(CreateOutput(model, context))}>Создать выходной интерфейс</Button>
            </div>
            {model.OutputSetups?.length == 0 && <div className='mb-5 text-center'>Пока нет выходных интерфейсов</div>}
            {(model.OutputSetups?.length ?? 0) > 0 && <>
                <div className="rounded-3 overflow-hidden mt-3">
                    <table className="table m-0 align-middle table-hover">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Название</th>
                                <th>Тип выхода</th>
                                <th>Тип обработки</th>
                                <th>Сервер обработки</th>
                                <th>Шаблон обработки</th>
                                <th>Потоков для обработки</th>
                                <th>Попытки обработки</th>
                                <th>Пауза, сек</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {model.OutputSetups?.map(output => {
                                const deployStatus = deployViewModel?.filter(dvm => dvm.OutputSetupId == output.Id)[0] || { IsDeployingNow: false, IsNeedDeploy: true }
                                return <tr>
                                    <td></td>
                                    <td className="table-editor">
                                        {output.Name}<br />
                                        <small>{output.Tag}</small>
                                    </td>
                                    <td>{output.OutputType}</td>
                                    <td>{output.ProcessingType}</td>
                                    <td>{output.ProcessingServer?.Title}</td>
                                    <td>{output.ProcessingTemplate?.Title}</td>
                                    <td>{output.ParallelTasks}</td>
                                    <td>{output.ProcessingTries}</td>
                                    <td>{output.ProcessingTriesPauseSeconds}</td>

                                    <td>
                                        <div className="d-flex justify-content-end align-items-center">
                                            {deployStatus.IsNeedDeploy && !deployStatus.IsDeployingNow && <Button variant="light" size="sm" onClick={() => deploy(output)}>Применить изменения</Button>}
                                            {deployStatus.IsDeployingNow && <Button variant="white" onClick={reload}><Icon.Clock height={20} width={20} /></Button>}
                                            {!deployStatus.IsDeployingNow && <Button variant="white" onClick={() => OpenIDE(context, output.Id)}>
                                                <Icon.CodeSlash height={20} width={20} /></Button>}

                                            <Button variant="white" onClick={() => setOutputEditor({ ...output })}>
                                                <Icon.Pencil height={20} width={20} /></Button>

                                            <Button variant="white" onClick={() => setOutputAiChat({ ...output })}>
                                                <Icon.Chat height={20} width={20} /></Button>


                                            <Button variant="white" onClick={() => {
                                                if (!window.confirm('Точно удалить?')) return;
                                                RemoveOutput(context, output.Id, reload)
                                            }}>
                                                <Icon.Trash3 height={20} width={20} />
                                            </Button>
                                        </div>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table></div>
            </>}


            <div className="mt-4 d-flex w-100 justify-content-between align-items-top">
                <h2 className="">Последние сообщения</h2>
                <div className="d-flex flex-row align-items-center gap-2">
                    Период:
                    <PeriodSelector
                        periodStart={f.FilterPeriodStart}
                        periodEnd={f.FilterPeriodEnd}
                        setPeriodStart={(unix) => setF({ ...f, FilterPeriodStart: unix })}
                        setPeriodEnd={(unix) => setF({ ...f, FilterPeriodEnd: unix })} />
                </div>
            </div>

            <Messages
                f={f}
                setF={setF}
                detailedInputDataId={detailedInputDataId}
                setDetailedInputDataId={setDetailedInputDataId}
                messagesTableHeight={500} />

            {detailedInputDataId && <Message inputDataId={detailedInputDataId} setInputDataId={setDetailedInputDataId}></Message>}

        </div>
        {inputEditor && <InputInterfaceEditor editor={inputEditor} setEditor={setInputEditor} reload={reload}></InputInterfaceEditor>}
        {outputEditor && <OutputInterfaceEditor editor={outputEditor} setEditor={setOutputEditor} reload={reload}></OutputInterfaceEditor>}
        {outputAiChat && <OutputAiChat editor={outputAiChat} setEditor={setOutputAiChat} reload={reload}></OutputAiChat>}

    </AdminTemplate >
    );
}