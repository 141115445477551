import * as Icon from 'react-bootstrap-icons';
import { Button, Form, InputGroup } from "react-bootstrap"
import { useState } from 'react';
import moment from 'moment';

export function PeriodSelector(props: {
    setPeriodStart: (value: number) => void,
    setPeriodEnd: (value: number) => void,
    periodStart: number | undefined,
    periodEnd: number | undefined
}) {
    const [selectorVisible, setSelectorVisible] = useState(false)
    var startMoment = props.periodStart ? moment.unix(props.periodStart).local() : moment()
    var endMoment = props.periodEnd ? moment.unix(props.periodEnd).local() : moment()

    return (
        <div className='position-relative'>
            <Form.Control type="text" size='sm' readOnly value={`${startMoment.format('DD.MM.YYYY HH:mm')} - ${endMoment.format('DD.MM.YYYY HH:mm')}`}
                style={{ width: '18rem' }} onClick={e => setSelectorVisible(true)}
                className='pe-4 bg-white' />
            <Button onClick={(e) => setSelectorVisible(s => !s)} variant='link' size='sm' className='position-absolute top-0 end-0 bottom-0 d-flex align-items-center justify-content-center'>
                <Icon.Grid />
            </Button>

            {selectorVisible && <div className='mt-1 p-2 bg-white border border-1 rounded position-absolute top-100 start-0 end-0 shadow-sm d-flex flex-column gap-2' style={{ zIndex: 1000 }}>
                <div>С</div>
                <div className='d-flex gap-1'>
                    <Form.Control type="date" value={startMoment.format('YYYY-MM-DD')} size='sm' onChange={(e) => {
                        var newDate = moment(e.target.value)
                        newDate.hour(startMoment.hour())
                        newDate.minute(startMoment.minute())
                        props.setPeriodStart(newDate.unix())
                    }} />
                    <Form.Control type="time" value={startMoment.format('HH:mm')} size='sm' onChange={e => {
                        var [hour, minute] = e.target.value.split(':').map(Number)
                        var newDate = moment(startMoment)
                        newDate.hour(hour)
                        newDate.minute(minute)
                        props.setPeriodStart(newDate.unix())
                    }} style={{
                        width: '8rem'
                    }} />
                </div>
                <div>По</div>
                <div className='d-flex gap-1'>
                    <Form.Control type="date" value={endMoment.format('YYYY-MM-DD')} size='sm' onChange={(e) => {
                        var newDate = moment(e.target.value)
                        newDate.hour(endMoment.hour())
                        newDate.minute(endMoment.minute())
                        props.setPeriodEnd(newDate.unix())
                    }} />
                    <Form.Control type="time" value={endMoment.format('HH:mm')} size='sm' onChange={e => {
                        var [hour, minute] = e.target.value.split(':').map(Number)
                        var newDate = moment(endMoment)
                        newDate.hour(hour)
                        newDate.minute(minute)
                        props.setPeriodEnd(newDate.unix())
                    }} style={{
                        width: '8rem'
                    }} />
                </div>
            </div>}
        </div>
    )
}